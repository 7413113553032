import React, { Component } from 'react';
import Form from './Form';

export class Fixa extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        return (
            <section class="section-white small-padding-bottom">


                <div class="container">

                    <div class="row">




                        <div class="col-md-6 margin-top-20">

                            <h3>Telefonia fixa e banda larga</h3>

                            <p >Ultravelocidade para sua empresa não parar nunca. </p>

                            <p>Planos com telefone fixo e internet para você falar ilimitado com qualquer telefone do Brasil e navegar sem preocupação. Conexão mais estável do Brasil, garantia de entrega em up e downloag e instalação gratuita. </p>
                            <button class="btn-blue scrool"  data-toggle="modal" data-target="#fixa">SOLICITAR CONTATO</button>

                        </div>
                        <div class="col-md-6 margin-top-10">

                            <img src="images/imagem2.png" class="width-100 box-shadow" alt="picture" />

                        </div>

                    </div>


                </div>
                <div class="modal fade" id="fixa" tabindex="-1" role="dialog" aria-labelledby="fixaLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{background: '#1e90ff', color:'#fff'}}>
                                
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body"  style={{background: '#1e90ff'}}>
                                <Form type={'fixa'} />
                            </div>


                        </div>
                    </div>
                </div>

            </section>
        )
    }
};

export default Fixa;
