import React, { Component } from 'react';


export class Banner extends Component {
    constructor(props) {
        super(props);
    
    }
 
    componentWillMount() {
     
    }
 
    render() {
        return (
            <section class="home-section" id="home">

           
            <div class="container">
    
               
                <div class="row">
                  
                    <div class="col-md-6 padding-top-80">
    
                          <h1>Soluções para grandes empresas e empresas que pensam grande. </h1>
    
                          <p>Somos especialistas redução de custos e otimização de serviços em telefonia celular, fixa e banda larga, soluções em locações de equipamentos e tecnologias avançadas em voz e dados. </p>
    
                     
    
                    </div>
                   
               
                   
                    <div class="col-md-6">
    
                          <img src="/images/fundo.png" class="hero-image width-100" alt="pic"/>
    
                    </div>
                  
                </div>
              
    
            </div>
           
    
        </section>
        )
    }
};

export default Banner;
