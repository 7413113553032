import React, { Component } from 'react';


export class Header extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        return (
            <header class="header">


                <nav class="navbar navbar-default navbar-fixed-top">


                    <div class="container">


                        <nav class="navbar navbar-expand-lg">


                            <a class="navbar-brand" href="#"><img src='/images/vivo.png' width='100px' /></a>



                            <a class="collapsed hide-md-up"  data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation">
                                <img class="" src='/images/evolua.png' width='100px' />
                            </a>

                            <div class="navbar-collapse collapse" id="navbarCollapse" >

                                <ul class="navbar-nav ml-auto">
                                    {/*     
                                <li><a href="#home">Home</a></li>
    
                                <li><a href="#about">About</a></li>
    
                                <li><a href="#pricing">Pricing</a></li>
    
                                <li><a href="#team">Team</a></li>
    
                                <li><a href="#showcase">Showcase</a></li>
    
                                <li><a href="#features">Features</a></li>
    
                                <li><a href="#blog">Blog</a></li>
    
                                <li><a href="#contact">Contact</a></li>
     */}
                                    <li><img src='/images/evolua.png' width='100px' /></li>

                                </ul>


                            </div>


                        </nav>


                    </div>


                </nav>


            </header>
        )
    }
};

export default Header;
