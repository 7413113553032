import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios'

export class Form extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.send = this.send.bind(this)
        this.state = {
            success: false,
            name: 'Vitor',
            email: null,
            number: null
        }
    }

    componentWillMount() {

    }
    send(e) {
        e.preventDefault();
        var body = {
            nome: this.state.name,
            email: this.state.email,
            telefone1: this.state.number,
            tipo: this.props.type,
            urlOrigin: window.location.href,
        }

        if (this.state.name && this.state.email && this.state.number) {
            axios.post('https://api-evolua.lucianospinturas.com.br/api/v1/contact', body)
            .then(response => {
                // If request is good...
                console.log(response.data)
                this.setState({success: true})
            })
            .catch((error) => {
                console.log(error)
            })
        } else {
            alert('Preencha todos os campos')
        }
    }
    async handleChange(e) {
        await this.setState({
            [e.target.name]: e.target.value
        })
    }
    render() {
        return (
            <section class="section-blue small-paddings" id="newsletter-section">

                <div class="container">


                    <div class="row">
                        {this.state.success ? <div class="col-md-12 text-center padding-top-50 padding-bottom-20">

                            <h3 class="white-text">Pronto, {this.state.name} !<br />
Em breve retornaremos o contato!</h3>

                        </div> :
                            <div class="col-md-12 text-center padding-top-50 padding-bottom-20">

                                <h3 class="white-text">Receba uma cotação hoje mesmo!<br />
                        Preencha seus dados e receba uma cotação personalizada para o serviço que precisar. </h3>

                            </div>}
                        {this.state.success ? null : <div class="col-md-12 padding-bottom-40">


                            <div class="newsletter_form_wrapper wow bounceIn" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'bounceIn' }}>


                                <div class="">


                                    <p class="newsletter_success_box" style={{ display: 'none' }}>We received your message and you'll hear from us soon. Thank You!</p>



                                    <form id="newsletter-form" class="newsletter-form text-center" onSubmit={this.send}>
                                        <div class='row'>
                                            <input id="email_newsletter" type="text" name="name" class='col-md-3 col-sm-12 margin-on-mobile' placeholder="Seu nome" onChange={this.handleChange} />
                                            <input id="email_newsletter" type="email" name="email" class='col-md-4 col-sm-12 margin-on-mobile' placeholder="Seu melhor email" onChange={this.handleChange} style={{ borderRadius: '0px' }} />
                                            <InputMask id="email_newsletter" placeholder="(DDD) + Telefone" type="text" class='col-md-3 col-sm-12 margin-on-mobile' name="number"  mask="(99) 9999-99999" onChange={this.handleChange} style={{ borderRadius: '0px' }}></InputMask>
                                            <input type="submit" value="ENVIAR" id="submit-button-newsletter" class='col-md-2 col-sm-12 margin-on-mobile' style={{margin:'auto', maxWidth : '340px'}} />
                                        </div>





                                    </form>


                                </div>


                            </div>

                        </div>}



                    </div>


                </div>


            </section>
        )
    }
};

export default Form;
