import React, { Component } from 'react';


export class QuemSomos extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        return (
            <section class="section-white small-padding-bottom">


                <div class="container">

                    <div class="row">




                        <div class="col-md-6 margin-top-30 text-center" style={{paddingTop:'10%'}}>

                            <p class="font-italic">Somos <b>parceiros Vivo Empresas</b> desde 2010 entre os maiores escritórios do Brasil.<br/><br/>

                            Acumulamos ao longo do tempo mais de 18 prêmios em desempenho de vendas e qualidade de atendimento ao cliente.
                            <br/><br/>
                            Nosso objetivo é entregar as soluções mais inteligentes para nossos clientes.
</p>



                        </div>
                        <div class="col-md-6 margin-top-10">

                            <img src="images/evolua2.png" class="width-100 " alt="picture" />

                        </div>

                    </div>


                </div>


            </section>
        )
    }
};

export default QuemSomos;
