import React, { Component } from 'react';
import './App.css';
import Header from './Header'
import Banner from './Banner'
import Form from './Form'
import Solucao from './Solucao'
import Celular from './Celular'
import Fixa from './Fixa'
import Avancada from './Avancada'
import QuemSomos from './QuemSomos'
import Contato from './Contato'
import Footer from './Footer'
export class App extends Component {
  constructor(props) {
    super(props)
    this.changeViewCelular = this.changeViewCelular.bind(this);
    this.changeViewFixa = this.changeViewFixa.bind(this);
    this.changeViewAvancada = this.changeViewAvancada.bind(this);
    this.state = {

      celularForm: false,
      fixaForm: false,
      avancadaForm: false,
    }

  }
  async changeViewCelular() {
    await this.setState({ celularForm: true })
  }
  async changeViewFixa() {
    await this.setState({ fixaForm: true })
  }
  async changeViewAvancada() {
    await this.setState({ avancadaForm: true })
  }
  render() {
    return (
      <div className="App">
        <Header />
        <Banner />
        <Form />
        <Solucao />
        <Celular changeView={this.changeViewCelular} />
        <Fixa changeView={this.changeViewFixa} />
        <Avancada changeView={this.changeViewAvancada} />
        <QuemSomos />
        <Contato />
        <Footer />
      </div>
    );
  }
}

export default App;
