import React, { Component } from 'react';


export class Footer extends Component {
    constructor(props) {
        super(props);
    
    }
 
    componentWillMount() {
     
    }
 
    render() {
        return (
            <div class="footer">
            
           
            <div class="container">
            
              
                <div class="row">
                
                  
                    <div class="col-md-12 text-center">
                       
                        <p>Ao informar seus dados através desta página, você concorda que a empresa EVOLUA TELECOM LTDA (CNPJ: 12093588/0001-54), parceiro Vivo Empresas, proprietária do referido site, possa usá-los para o envio de ofertas de produtos e serviços, comprometendo-se a resguardar e proteger sua privacidade.</p>
                                             
                       
                        {/* <ul class="footer_social">
    
                            <li>
                                <a href="#">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
    
                            <li>
                                <a href="#">
                                    <i class="fab fa-pinterest"></i>
                                </a>
                            </li>
    
                            <li>
                                <a href="#">
                                    <i class="fab fa-facebook-square"></i>
                                </a>
                            </li>
    
                            <li>
                                <a href="#">
                                    <i class="fab fa-instagram"></i>
                                </a>
                            </li>
    
                            <li>
                                <a href="#">
                                    <i class="fab fa-skype"></i>
                                </a>
                            </li>
    
                            <li>
                                <a href="#">
                                    <i class="fab fa-dribble"></i>
                                </a>
                            </li>
    
                        </ul> */}
                      
                        
                    </div>
                   
                    
                </div>
               
                
            </div>
          
                    
        </div>
        )
    }
};

export default Footer;
