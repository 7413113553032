import React, { Component } from 'react';
import Form from './Form';


export class Celular extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        return (
            <section class="section-blue">


                <div class="container">


                    <div class="row">


                        <div class="col-md-6">


                            <div class="video-popup-wrapper">


                                <div class="popup-gallery">

                                    <img src="images/imagem1.png" class="width-100 video-popup-image" alt="pic" />


                                </div>


                            </div>

                        </div>

                        <div class="col-md-6 padding-top-30 padding-left-20">

                            <h2 class="white-text">Telefonia celular</h2>

                            <p class="white-text">Aumente a produtividade e facilite o contato com clientes, fornecedores e equipes com um plano de celular ilimitado com total mobilidade.</p>

                            <p class="white-text">Complete seu plano com os melhores smartphones. Confira todos os modelos, veja quais são as melhores opções para você e aproveite.</p>

                            <button class="btn-white scrool" data-toggle="modal" data-target="#celular">SOLICITAR CONTATO</button>
                        </div>


                    </div>


                </div>

                <div class="modal fade" id="celular" tabindex="-1" role="dialog" aria-labelledby="celularLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{background: '#1e90ff', color:'#fff'}}>
                                
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body"  style={{background: '#1e90ff'}}>
                                <Form type={'celular'} />
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

export default Celular;
