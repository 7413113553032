import React, { Component } from 'react';
import Form from './Form'

export class Avancada extends Component {
    constructor(props) {
        super(props);
    
    }
 
    componentWillMount() {
     
    }
 
    render() {
        return (
            <section class="section-blue">

           
            <div class="container">
    
            
                <div class="row">
    
                  
                    <div class="col-md-6">
                        
                      
                        <div class="video-popup-wrapper">
    
                       
                            <div class="popup-gallery">
                                
                                <img src="images/imagem3.png" class="width-100 video-popup-image" alt="pic"/>
    
                               
    
                            </div>
                        
    
                        </div>
                       
                    </div>
                  
                    <div class="col-md-6 padding-top-30 padding-left-20">
    
                        <h2 class="white-text">Soluções avançadas</h2>
    
                        <p class="white-text">Link dedicado, DDR via IP, virtualização de servidores, locação de notebooks, desktops e impressoras para deixar sua empresa 100% digital. </p>
    
                        <p class="white-text">Consulte nossas soluções em internet e voz avançadas para sua empresa manter-se sempre conectada aonde quer que esteja. </p>
                        <button class="btn-white scrool" data-toggle="modal" data-target="#avancada">SOLICITAR CONTATO</button>
                    </div>
                
               
                </div>
               
                
            </div>
          
            <div class="modal fade" id="avancada" tabindex="-1" role="dialog" aria-labelledby="avancadaLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style={{background: '#1e90ff', color:'#fff'}}>
                                
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body"  style={{background: '#1e90ff'}}>
                                <Form type={'avancada'} />
                            </div>


                        </div>
                    </div>
                </div>
        </section>
        )
    }
};

export default Avancada;
