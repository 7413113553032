import React, { Component } from 'react';


export class Contato extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        return (
            <section class="section-white section-bottom-border" id="features">


                <div class="container">


                    <div class="row">


                        <div class="col-md-12 text-center padding-bottom-10">

                            <h2 class="section-title">Entre em contato conosco: (41) 3093-9768</h2>

                        </div>


                    </div>

                    <div class="row">


                        <div class="col-md-4">

                            <div class="feature-box wow fadeIn" data-wow-delay="0.25s" style={{ visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeIn' }}>

                             

                                <h4 class='text-left'>Recife:</h4>

                                <p  class='text-left'>Rua Theodomiro Selva, 491 A<br />
Contato: (81) 3048-1555</p>

                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="feature-box wow fadeIn" data-wow-delay="0.5s" style={{ visibility: 'visible', animationDelay: ' 0.5s', animationName: 'fadeIn' }}>

                             
                                <h4  class='text-left'>Campinas: </h4>

                                <p  class='text-left'>Av. Princesa D´ Oeste, 1886 - Jardim Paraíso<br/>
Contato: (19) 3255-4660</p>

                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="feature-box wow fadeIn" data-wow-delay="0.75s" style={{ visibility: 'visible', animationDelay: '0.75s', animationName: 'fadeIn' }}>

                             

                                <h4  class='text-left'>Porto Alegre:</h4>

                                <p  class='text-left'>Av. Cel. Lucas de Oliveira, 1937 - Sala 501<br/>
Contato : (51) 3030-0007</p>

                            </div>
                        </div>


                    </div>

                    <div class="row">


                        <div class="col-md-4">

                            <div class="feature-box wow fadeIn" data-wow-delay="1s" style={{ visibility: 'visible', animationDelay: '1s', animationName: 'fadeIn' }}>

                                

                                <h4  class='text-left'>Ponta Grossa:</h4>

                                <p  class='text-left'>Rua XV de Novembro, 308 - 10º andar - Sala 103<br/>
Contato: (42) 3323-4460</p>

                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="feature-box wow fadeIn" data-wow-delay="1.25s" style={{ visibility: 'visible', animationDelay: '1.25s', animationName: 'fadeIn' }}>

                                <h4  class='text-left'>Maringá:</h4>

                                <p  class='text-left'>Rua Pioneiro Arlindo Pedralli, 1311<br/>
Contato: (44) 3305-5261</p>

                            </div>
                        </div>

                        <div class="col-md-4">

                            <div class="feature-box wow fadeIn" data-wow-delay="1.5s" style={{ visibility: 'visible', animationDelay: '1.5s', animationName: 'fadeIn' }}>

                         

                                <h4  class='text-left'>Londrina:</h4>

                                <p  class='text-left'>Av. Higienópolis, 1505 sala 202<br/>
Contato: (43) 3026-1469</p>

                            </div>
                        </div>


                    </div>


                </div>


            </section>
        )
    }
};

export default Contato;
