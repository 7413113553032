import React, { Component } from 'react';


export class Solucao extends Component {
    constructor(props) {
        super(props);
    
    }
 
    componentWillMount() {
     
    }
 
    render() {
        return (
            <section class="section-grey section-top-border" id="about">

            <div class="container">
    
              
                <div class="row">
    
                   
                    <div class="col-md-12 text-center">
    
                        <h2 class="section-title">Soluções completas para o seu negócio</h2>
    
                        <p class="section-subtitle">Os melhores serviços e tecnologias para sua empresa sempre sair na frente.</p>
                        
                    </div>
                   
                    <div class="col-md-4">
    
                        <div class="main-services">
    
                            <img src="images/imagem1.1.png" class="width-100" alt="pic"/>
    
                            <h3>Telefonia celular</h3>
    
                            <p>Planos e aparelhos celulares com ligações ilimitadas e muita internet para sua empresa não parar nunca. </p>
    
                          
                            
                        </div>
    
                    </div>
                  
                    <div class="col-md-4">
    
                        <div class="main-services">
    
                            <img src="images/imagem2.2.png" class="width-100" alt="pic"/>
    
                            <h3>Fixa e banda larga</h3>
    
                            <p>A melhor tecnologia de fixa e banda larga que não deixa seu negócio na mão. </p>
    
                        
                            
                        </div>
    
                    </div>
                  
                    <div class="col-md-4">
    
                        <div class="main-services">
    
                            <img src="images//imagem3.3.png" class="width-100" alt="pic"/>
    
                            <h3>Soluções para TI</h3>
    
                            <p>Locação de notebooks, desktops e impressoras e tecnologias em voz e dados. </p>
    
                       
                            
                        </div>
    
                    </div>
                   
               
                </div>
               
                
            </div>
            
    
        </section>
        )
    }
};

export default Solucao;
